import PropTypes from "prop-types";
import React from "react";
import "./tarif-cards.css";

export default class MultilineCards extends React.Component {
  constructor(props) {
    super(props);
  }

  clickHandler = () => {
    const el = document.getElementById("calc-content");
    el?.scrollIntoView({ alignToTop: true, behavior: "smooth" });
  };

  render() {
    return (
      <div className="tarif-cards">
        {this.props.items?.map((i) => {
          const rating = Array.from(Array(i.Rating).keys()).map((i) => (
            <span className="tarif-cards-card-star" key={i} />
          ));
          return (
            <div key={i.Caption} className="tarif-cards-card">
              <p className="tarif-cards-card-caption">{i.Caption}</p>
              {i.Items?.map((f) => {
                return (
                  <div key={f.Key} className="tarif-cards-card-field">
                    <span>{f.Key}</span>
                    <span>{f.Value}</span>
                  </div>
                );
              })}
              <div className="tarif-cards-card-rating">{rating}</div>
              <button className="tarif-cards-card-btn" onClick={() => this.clickHandler()}>
                Выбрать
              </button>
            </div>
          );
        })}
      </div>
    );
  }
}

MultilineCards.propTypes = {
  items: PropTypes.array,
};

MultilineCards.defaultProps = {
  items: [],
};
