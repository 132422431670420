import React from "react";
import AdvStartup from "~components/adv/advStartup";
import OverlayCards from "~components/card/overlayCards";
import MultilineCards from "~components/card/multilineCards";
import MiniCards from "../components/card/miniCards";
import Tabs from "../components/tab/tabs";
import Layout from "../components/layout";
import Request from "../components/request/request";
import Seo from "../components/seo";
import UserBehaviorStore from "../storages/userBehaviorStore";
import * as Utm from "~components/helpers/utm";
import "react-multi-carousel/lib/styles.css";

import {
  getModules,
  getLinksByKeyStartWith,
  getSnippetContentByKey,
  prepareText,
} from "~components/helpers/pageHelper";
import LocalStore from "../request/storages/LocalStore";

const userBeh = new UserBehaviorStore();
const localStore = new LocalStore();

const PageTpl = (props) => {
  const siteName = props.pageContext.siteName;

  Utm.setCookie(Utm.parseFromString());
  if (typeof window !== "undefined") {
    userBeh.init();
    localStore.setSiteName(siteName);
  }

  const conf = { siteName: siteName };
  const title = props.pageContext.title;
  const description = props.pageContext.description;
  const h1 = props.pageContext.h1;
  const htmlContent = prepareText(props.pageContext.htmlContent, conf);

  const footerHtml = prepareText(
    getSnippetContentByKey(props.pageContext.txtSnippets, "footer"),
    conf
  );
  const advNameReqFail = props.pageContext.config.adv_name_req_fail; // рекламный набор при отказе в заёме

  const pageSnippets = props.pageContext.pageSnippets || [];
  const filteredlinkSnippets = props.pageContext.linkSnippets.filter((i) => {
    return pageSnippets.some((some) => some === i.Id);
  });

  const topMenus = getLinksByKeyStartWith(filteredlinkSnippets, "top_menu");
  const bottomMenus = getLinksByKeyStartWith(filteredlinkSnippets, "bottom_menu");
  const trackers = props.pageContext.trackers;
  const yaTrackers = trackers.filter((i) => i.Type === 0).map((i) => i.Id);
  let canonical = `https://${siteName}${props.pageContext.route}/`;
  if (props.pageContext.route === "/") canonical = null;
  const isIndex = props.pageContext.route === "/";
  if (isIndex) canonical = null;

  const modules = getModules(htmlContent);

  const cardMultilineId = modules.find((x) => x.name === "card_multiline")?.id;
  const cardMultilineItems = cardMultilineId
    ? props.pageContext.cardMLSnippets.find((x) => x.Id === cardMultilineId)?.Items
    : [];

  const cardOverlayId = modules.find((x) => x.name === "card_overlay")?.id;
  const cardOverlayItems = cardOverlayId
    ? props.pageContext.cardOverlaySnippets.find((x) => x.Id === cardOverlayId)?.Items
    : [];

  return (
    <Layout
      footerHtml={footerHtml}
      title={siteName}
      topMenus={topMenus}
      bottomMenus={bottomMenus}
      trackers={trackers}
      pixels={props.pageContext.pixels}
    >
      <Seo title={title} description={description} canonical={canonical} />
      <div className="bg-gray">
        <div className="container">
          <h1>{h1}</h1>
          <p>Быстро и просто</p>
          <Request advNameFail={advNameReqFail} yaTrackers={yaTrackers} userBeh={userBeh} />
        </div>
      </div>

      <div className="bg-black">
        <div className="container">
          <section>
            <div className="center">Поиск займов, для всех категорий граждан...</div>
            <Tabs />
          </section>
        </div>
      </div>

      <div className="bg-white">
        <div className="container">
          <section>
            <h3>
              <span>Займы</span> на любые нужды
            </h3>
            <p>Выберите нужный вам займ...</p>
            <MiniCards />
          </section>

          <section>
            <div className="pop-offers">
              <h3>
                Наши лучшее <span>предложения</span>
              </h3>
              <p>
                Подобрали специально для <span>Вас</span>
              </p>
              <AdvStartup useCarusel={true} name="topzaem_index" yaTrackers={yaTrackers} />
            </div>
          </section>

          {cardOverlayItems && cardOverlayItems.length > 0 && (
            <section>
              <h3>
                Способы получения <span>заёма</span>
              </h3>
              <p>Предлагаем целый ряд удобных способов для получения заёма без отказа</p>
              <OverlayCards items={cardOverlayItems} />
            </section>
          )}

          {cardMultilineItems && cardMultilineItems.length > 0 && (
            <section>
              <h3>
                <span>Тарифы</span> на займы
              </h3>
              <p>Условия и порядок предоставления займов</p>
              <MultilineCards items={cardMultilineItems} />
            </section>
          )}
        </div>
      </div>
    </Layout>
  );
};
export default PageTpl;
