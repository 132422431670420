import PropTypes from "prop-types";
import React from "react";
import "./cards.css";

export default class OverlayCards extends React.Component {
  constructor(props) {
    super(props);
  }

  clickHandler = () => {
    const el = document.getElementById("calc-content");
    el?.scrollIntoView({ alignToTop: true, behavior: "smooth" });
  };

  render() {
    return (
      <div className="cards">
        {this.props.items?.map((i) => {
          const rating = Array.from(Array(i.Rating).keys()).map((i) => (
            <span className="cards-card-star" key={i} />
          ));
          return (
            <div key={i.Id} className="cards-card" onClick={() => this.clickHandler()}>
              <div className="cards-card-img-container">
                <img className="cards-card-img" alt="" src={i.ImgId} loading="lazy" />
              </div>

              <div className="cards-card-txt-container">
                <div className="cards-card-txt-content">
                  <p>
                    <strong>{i.Caption}</strong>
                  </p>
                  <p>{i.Text}</p>
                  <div>{rating}</div>
                </div>
              </div>

              <div className="cards-card-overlay">
                <div className="cards-card-overlay-content">
                  <p>
                    <strong>{i.Caption}</strong>
                  </p>
                  <hr className="cards-card-overlay-content-line"></hr>
                  <p className="cards-card-overlay-content-descr">{i.HiddenText}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

OverlayCards.propTypes = {
  items: PropTypes.array,
};

OverlayCards.defaultProps = {
  items: [],
};
