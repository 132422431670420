import React from "react";
import "./mini-cards.css";

const items = [
  { text: "На ремонт авто", descr: "Не откладывайте ремонт вашего автомобиля", img: "car.png" },
  { text: "На шопинг", descr: "Покупайте понравившиеся Вам вещи и аксессуары", img: "shop.png" },
  { text: "На лечение", descr: "Заботьтесь о здровье, не запускайте болезни", img: "health.png" },
  { text: "Делайте ставки", descr: "Играйте в онлайн иры, пополняйте балансы", img: "game.png" },
  { text: "На отдых", descr: "Путишествуйте, отдыхайте, ловите моменты жизни", img: "relax.png" },
  {
    text: "На технику",
    descr: "Дарите близким подарки, радуйте себя новыми гаджетами",
    img: "tech.png",
  },
  {
    text: "На оплату ЖКХ",
    descr: "Оплачивайте услуги ЖКХ, аренду квартир и помещений",
    img: "home.png",
  },
  {
    text: "На обучение",
    descr: "Оплачивайте услуги репетиторов, секции и кружки для ваших детей",
    img: "edu.png",
  },
  {
    text: "На ремонт",
    descr: "Срочный ремонт квартир доступен Вам в любое время",
    img: "repair.png",
  },
  {
    text: "До зарплаты",
    descr: "На случай задержки зарплаты или кассового разрыва",
    img: "money.png",
  },
  { text: "На учебу", descr: "Быстрая помощь студентам на необходимые нужды", img: "edu2.png" },
  {
    text: "На закупки",
    descr: "Развивайте свой малый бизнес, делайте необходимые закупки",
    img: "other.png",
  },
];

export default class MiniCards extends React.Component {
  constructor(props) {
    super(props);
  }

  clickHandler = () => {
    const el = document.getElementById("calc-content");
    el.scrollIntoView({ alignToTop: true, behavior: "smooth" });
  };

  render() {
    return (
      <div className="mini-cards">
        {items.map((i) => {
          return (
            <div key={i.text} className="mini-cards-card" onClick={() => this.clickHandler()}>
              <div className="mini-cards-card-img">
                <img alt="" src={`../img/${i.img}`} loading="lazy" />
              </div>

              <p>{i.text}</p>
              <p>{i.descr}</p>
              <span className="mini-cards-card-arrow" onClick={() => {}}></span>
            </div>
          );
        })}
      </div>
    );
  }
}

MiniCards.propTypes = {};
