/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import "./tabs.css";
import { Input } from "../../request/views/Inputs/Input";
import { Select } from "../../request/views/Inputs/Select";
import { Adv } from "~components/adv/adv";
import { AdvClient } from "~components/clients/advClient";
import config from "~components/helpers/config";
import * as Utm from "~components/helpers/utm";

export default class Tabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = { items: [] };
  }

  componentDidMount() {
    const client = new AdvClient(config.adv);

    const utm = Utm.parseFromCookie();
    const originUtm = Utm.utmToOriginUtmString(utm);
    client.get(`${"topzaem_index"}${originUtm}`).then((data) => {
      this.setState({ items: data });
    });
  }

  apply = () => {};

  render() {
    return (
      <div className="_tab">
        <div className="_tab-tabs">
          <div className="_tab-tabs-tab active">Займы</div>
          <div className="_tab-tabs-tab">Страховки</div>
          <div className="_tab-tabs-tab">Кредитные карты</div>
        </div>

        <div className="_tab-tab-content">
          <div className="_tab-tab-content-form">
            <Input
              id="sum"
              name="sum"
              onChange={() => {}}
              onBlur={() => {}}
              type="number"
              required="required"
              placeholder={""}
              title={"Укажите сумму заёма"}
              maxLength="10"
              errorMessage="Укажите сумму заёма"
              label="Сумма заёма"
            />
            <Select
              id="dur"
              name="dur"
              variants={[]}
              onChange={() => {}}
              errorMessage="Выберите Срок заёма"
              label="Срок заёма"
            />
            <Select
              id="region"
              name="region"
              variants={[]}
              onChange={() => {}}
              errorMessage="Выберите регион проживания"
              label="Регион проживания"
            />
            <div className="input" style={{ marginLeft: "auto", width: "auto" }}>
              <button
                className="button fixed"
                onClick={(e) => {
                  this.apply(e);
                }}
              >
                Подобрать
              </button>
            </div>
          </div>
          <div className="_tab-tab-content-result">
            {this.state.items.map((i) => (
              <Adv item={i} key={i.key} />
            ))}
          </div>
        </div>
      </div>
    );
  }
}
